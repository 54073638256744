import {
  handleDisabledEvent,
  handleKeyDownCapture,
  handleKeyDownCaptureDisabled,
} from '../../../../utils/eventHandler';
import {
  BaseButtonStyle,
  ButtonWrapper,
  DestructiveButtonStyle,
  DestructiveGhostButtonStyle,
  GhostButtonStyle,
  PrimaryButtonStyle,
  SecondaryButtonStyle,
} from './ButtonStyles';
import { Loader } from './Loader';
import { BaseButtonProps, ButtonProps } from './types';
import { forwardRef } from 'react';
import styled from 'styled-components';

const BaseButton = styled.button<BaseButtonProps>`
  ${BaseButtonStyle}
`;

const PrimaryButton = styled(BaseButton)<ButtonProps>`
  ${PrimaryButtonStyle}
`;

const SecondaryButton = styled(BaseButton)<ButtonProps>`
  ${SecondaryButtonStyle}
`;

const DestructiveButton = styled(BaseButton)`
  ${DestructiveButtonStyle}
`;

const GhostButton = styled(BaseButton)`
  ${GhostButtonStyle}
`;

const DestructiveGhostButton = styled(BaseButton)`
  ${DestructiveGhostButtonStyle}
`;

const buttonVariations = {
  primary: PrimaryButton,
  secondary: SecondaryButton,
  destructive: DestructiveButton,
  ghost: GhostButton,
  destructiveGhost: DestructiveGhostButton,
};

/**
 * Buttons are used primarily for actions, such as “Add”, “Apply”, “Cancel”, or “Create”.
 *
 * @deprecated Use new MDSButton from @midwest/web/button instead */
export const MDSButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      variant = 'primary',
      size = 'normal',
      onClick,
      type = 'button',
      disabled,
      loading,
      fullWidth,
      ...rest
    }: ButtonProps,
    ref,
  ) => {
    const ButtonVariation = buttonVariations[variant];

    return (
      <ButtonWrapper $disabled={disabled}>
        <ButtonVariation
          type={type}
          onClick={disabled || loading ? () => handleDisabledEvent : onClick}
          onKeyDownCapture={
            disabled || loading
              ? handleKeyDownCaptureDisabled
              : handleKeyDownCapture
          }
          variant={variant}
          size={size}
          $loading={loading}
          fullWidth={fullWidth}
          aria-disabled={disabled}
          disabledStyle={disabled}
          disabled={disabled}
          {...rest}
          ref={ref}
        >
          <Loader loading={loading} variant={variant} />
          {children}
        </ButtonVariation>
      </ButtonWrapper>
    );
  },
);

MDSButton.displayName = 'MDSButton';
