import { Spinner } from '../../../../assets/svg/loaders/spinner';
import { ButtonVariant } from './types';
import { colors } from '@thrivent/midwest-shared';
import styled, { css } from 'styled-components';

/* this transition must stay in sync between button and loader to avoid visual artifacts */
export const loadingTransition = css`
  @media (prefers-reduced-motion: no-preference) {
    transition: all 0.2s ease-in-out;
  }
`;

const LoaderContainer = styled.div<{ $loading?: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0.3125rem;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;

  ${loadingTransition}

  ${(p) =>
    p.$loading &&
    css`
      visibility: visible;
      opacity: 1;
      z-index: 1;
    `}
`;

export const LoaderPrimary = styled(LoaderContainer)`
  background-color: ${colors.identityBrandPrimaryHeavy};
`;

export const LoaderSecondary = styled(LoaderContainer)`
  background-color: ${colors.neutralLightHeavy};
`;

export const LoaderDestructive = styled(LoaderContainer)`
  background-color: ${colors.sentimentNegativeHeavy};
`;

export const LoaderGhost = styled(LoaderContainer)`
  background-color: ${colors.neutralLightHeavy};
`;

export const LoaderDestructiveGhost = styled(LoaderContainer)`
  background-color: ${colors.sentimentNegativeWeak};
`;

const loaderVariations = {
  primary: LoaderPrimary,
  secondary: LoaderSecondary,
  destructive: LoaderDestructive,
  ghost: LoaderGhost,
  destructiveGhost: LoaderDestructiveGhost,
};

interface LoaderProps {
  loading?: boolean;
  variant?: ButtonVariant;
}

/**
 * Covers the whole button with a loading spinner and background color
 */
export const Loader = ({ loading, variant = 'primary' }: LoaderProps) => {
  const LoaderVariation = loaderVariations[variant];

  return (
    <LoaderVariation $loading={loading}>
      <Spinner />
    </LoaderVariation>
  );
};
